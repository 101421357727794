import Vue from 'vue'
import * as api from '../../api'
import * as types from '../mutation-types'
import utils from '@/utils/utils'
const isMobile = utils.isMobile()

const state = {
  isMobile: isMobile,
  basicData: {
    title: '',
    logo: '',
    company: '',
    address: '',
    record_number: '',
    copyright: ''
  },
  bannerData: {},
  projectData: {
    totalCount: 0,
    pageNo: 1,
    pageSize: 10,
    list: {}
  },
  projectDetailData: {},
  agreeData: {}
}

const getters = {
  isMobile: state => state.isMobile,
  basicData: state => state.basicData,
  bannerData: state => state.bannerData,
  projectData: state => state.projectData,
  projectDetailData: state => state.projectDetailData,
  agreeData: state => state.agreeData
}

const mutations = {
  [types.GET_BASIC_SUCCESS] (state, { data }) {
    Vue.set(state, 'basicData', data)
  },
  [types.GET_BASIC_FAILED] (state) {
    Vue.set(state, 'basicData', {})
  },
  [types.GET_BANNER_IMG_SUCCESS] (state, { data }) {
    Vue.set(state, 'bannerData', data)
  },
  [types.GET_BANNER_IMG_FAILED] (state) {
    Vue.set(state, 'bannerData', {})
  },
  [types.GET_PROJECT_LIST_SUCCESS] (state, { data }) {
    Vue.set(state, 'projectData', data)
  },
  [types.GET_PROJECT_LIST_FAILED] (state) {
    Vue.set(state, 'projectData', {})
  },
  [types.GET_PROJECT_DETAIL_SUCCESS] (state, { data }) {
    Vue.set(state, 'projectDetailData', data)
  },
  [types.GET_PROJECT_DETAIL_FAILED] (state) {
    Vue.set(state, 'projectDetailData', {})
  },
  [types.GET_AGREE_SUCCESS] (state, { data }) {
    Vue.set(state, 'agreeData', data)
  },
  [types.GET_AGREE_FAILED] (state) {
    Vue.set(state, 'agreeData', {})
  }
}

const actions = {
  getBasic ({ commit }) {
    api.getBasic(data => {
      commit(types.GET_BASIC_SUCCESS, data)
    }, () => {
      commit(types.GET_BASIC_FAILED)
    })
  },
  getBannerData ({ commit }) {
    api.getBannerImg(data => {
      commit(types.GET_BANNER_IMG_SUCCESS, data)
    }, () => {
      commit(types.GET_BANNER_IMG_FAILED)
    })
  },
  getProjectData ({ commit }) {
    api.getProjectData(data => {
      commit(types.GET_PROJECT_LIST_SUCCESS, data)
    }, () => {
      commit(types.GET_PROJECT_LIST_FAILED)
    })
  },
  getProjectDetail ({ commit }, obj) {
    api.getProjectDetail(obj, data => {
      commit(types.GET_PROJECT_DETAIL_SUCCESS, data)
    }, () => {
      commit(types.GET_PROJECT_DETAIL_FAILED)
    })
  },
  getAgree ({ commit }) {
    api.getAgree(data => {
      commit(types.GET_AGREE_SUCCESS, data)
    }, () => {
      commit(types.GET_AGREE_FAILED)
    })
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
