<template>
  <div :class="isMobile?'mobile-footbar':'footbar'">
    <div class="text-center">
      <p>公司名称：{{ basicData.company }}&nbsp;|&nbsp;公司地址：{{ basicData.address }}</p>
      <p>{{ basicData.copyright }}</p>
      <p v-html="basicData.record_number"></p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'footBar',
  computed: {
    ...mapGetters(['isMobile', 'basicData'])
  }
}
</script>

<style lang="scss">

</style>
