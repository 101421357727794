<template>
  <div :class="isMobile?'mobile-banner':'banner'">
    <img :src="bannerData.image" :alt="bannerData.title">
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'bannerImg',
  computed: mapGetters(['isMobile', 'bannerData'])
}
</script>

<style lang="scss">

</style>
