<template>
  <div :class="isMobile?'mobile-topbar':'topbar'">
    <div class="topbarMenu">
      <a href="/" class="logo">{{basicData.title}}</a>
      <a href="/" :class="{ active: ''=== currentActive }" @click="addClass()">首页</a>
      <i></i>
      <a href="#/agree" :class="{ active: 'agree'=== currentActive }" @click="addClass('agree')">用户协议</a>
      <i></i>
      <a href="#/about" :class="{ active: 'about'=== currentActive }" @click="addClass('about')">关于我们</a>
    </div>
    <div class="agenInfo"></div>
    <!-- <div class="agenInfo" v-if="!agenInfo.name">
      <a href="#/login" :class="{ active: 'login'=== currentActive }" @click="addClass('login')">登录</a>
      <i></i>
      <a href="#/register" :class="{ active: 'register'=== currentActive }" @click="addClass('register')">注册</a>
    </div>
    <div class="agenInfo" v-else>
      <div trigger="click">
        <a class="user-info" @click="dropdownShow(isShow)">
          <span>欢迎您，{{agenInfo.name}}</span>
          <img class="down" src="../assets/images/zg_down.png" v-if="!isShow" />
          <img class="down" src="../assets/images/zg_up.png" v-else />
        </a>
        <div slot="dropdown" v-if="isShow">
          <div class="clearfix">
            <span @click="$router.push({path: '/account/info?id='+agenInfo.id})">个人中心</span>
          </div>
          <div class="clearfix">
            <span @click="loginout">退出登录</span>
          </div>
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'topBar',
  data () {
    return {
      currentActive: '',
      isShow: false,
    }
  },
  computed: {
    ...mapGetters(['isMobile', 'agenInfo', 'basicData'])
  },
  created () {},
  methods: {
    ...mapActions(['getAgentLogout']),
    addClass (index) {
      this.currentActive = index
    },
    loginout () {
      this.getAgentLogout()
      this.$router.push({ path: '/login' })
    },
    dropdownShow (isShow) {
      if (isShow) {
        this.isShow = false
      } else {
        this.isShow = true
      }
    }
  }
}
</script>

<style lang="scss">

</style>
