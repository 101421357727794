import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import ToastPage from '@/components/toast/index.js'

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');

Vue.prototype.showSuccess = function (title) {
  ToastPage({ message: title, type: 'success' })
}

Vue.prototype.showError = function (title) {
  ToastPage({ message: title, type: 'warning' })
}
