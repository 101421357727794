export default class utils {
  /**
   *
   * @desc   判断是否为手机号
   * @param  {String|Number} str
   * @return {Boolean}
   */
  static isPhoneNum (str) {
    return /^(0|86|17951)?(1[3-9][0-9])[0-9]{8}$/.test(str)
  }

  /**
   *
   * @desc   判断是否为手机号
   * @param  {String|Number} str
   * @return {Boolean}
   */
  static getTodayUnix () {
    return new Date(new Date().setHours(0, 0, 0, 0)) / 1000
  }

  static isMobile () {
    let flag = navigator.userAgent.match(
      /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
    );

    if (flag !== null) {
      return true
    } else {
      return false
    }
  }

  static isIPhone () {
    let flag = navigator.userAgent.match(
      /(pad|pod|iPhone|iphone|iPod|ipod|ios|iPad|ipad|Mac OS)/i
    );

    if (flag !== null) {
      return true
    } else {
      return false
    }
  }

  static getUnix () {
    return parseInt(new Date() / 1000)
  }

  static getToday () {
    var now = new Date();
    return now.toLocaleDateString();
  }

  static getDeviceId () {
    return this.getRand() + this.getRand() + this.getRand() + this.getRand() + this.getRand() + this.getRand() + this.getRand() + this.getRand()
  }

  static getRand () {
    return Math.floor(65536 * (1 + Math.random())).toString(16).substring(1)
  }

  static getTimeAgo (ago) {
    var now = this.getUnix()
    var seconds = now - ago
    var interval = Math.floor(seconds / 31536000)
    if (interval > 1) {
      return interval + ' 年前'
    }
    interval = Math.floor(seconds / 2592000)
    if (interval > 1) {
      return interval + ' 月前'
    }
    interval = Math.floor(seconds / 86400)
    if (interval > 1) {
      return interval + ' 日前'
    }
    interval = Math.floor(seconds / 3600)
    if (interval > 1) {
      return interval + ' 小时前'
    }
    interval = Math.floor(seconds / 60)
    if (interval > 1) {
      return interval + ' 分钟前'
    }
    return Math.floor(seconds) + ' 秒前'
  }
}
