<template>
  <div id="app">
    <top-bar/>
    <banner-img/>
    <router-view/>
    <foot-bar/>
  </div>
</template>

<script>

import { mapActions, mapGetters } from 'vuex'
import { BannerImg, TopBar, FootBar } from './components'

export default {
  name: 'App',
  data () {
    return {}
  },
  components: {
    TopBar,
    BannerImg,
    FootBar,
  },
  computed: {
    ...mapGetters(['basicData', 'bannerData', 'agreeData', 'projectData']),
  },
  methods: {
    ...mapActions([
      'getBasic',
      'getBannerData',
      'getAgree',
      'getProjectData'
    ]),
  },
  created () {
    document.title = window.documentTitle
    this.getBasic()
    this.getBannerData()
    this.getAgree()
    this.getProjectData()
  },
}
</script>

<style lang="scss">
  @import "@/assets/styles/index.scss";
</style>
