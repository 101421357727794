<template>
  <div class="mobile-main">
    <div class="agree">
      <h3>{{agreeData.title}}</h3>
      <div v-html="agreeData.content"></div>
      <p align="center">
        <button type="button" class="btn btn-ok" @click="agreeClick"><span>同意</span></button>
        <button type="button" class="btn btn-no" @click="noAgreeClick"><span>不同意</span></button>
      </p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'mobileAgree',
  computed: {
    ...mapGetters(['agreeData'])
  },
  methods: {
    agreeClick () {
      this.$router.push({ path: '/register' })
    },
    noAgreeClick () {
      this.$router.push({ path: '/' })
    }
  }
}
</script>

<style lang="scss">
  @import "@/assets/mobile/index.scss";
</style>
